@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$bgColor: #121212;
$white: white;
$rubik: 'Rubik', sans-serif;

body {
    margin: 0;
    background-color: $bgColor;
    color: $white;
    font-family: $rubik;
    overflow: hidden;
}

a {
    color: inherit !important;
    text-decoration: none !important;
}

.home {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .logo {
        img {
            width: 120px;
        }
    }
    .text {
        text-transform: uppercase;
        font-size: 42px;
        font-weight: 600;
    }
}

.powered {
    width: 100vw;
    position: absolute;
    bottom: 60px;
    left: 0;
    text-align: center;
    font-size: 18px;

    span {
        font-weight: 600;
    }
}